import React from "react";
import {graphql} from "gatsby";
import SearchForm from "../../components/SearchForm.js";
import CopyImageBlock from "../../components/CopyImageBlock.js";
import LinkBlock from "../../components/LinkBlock.js";
import LinkList from "../../components/LinkList.js";
import ImageGrid from "../../components/ImageGrid.js";
import BlogList from "../../components/BlogList.js";
import PropertyList from "../../components/PropertyList.js";
import Hero from "../../components/Hero";
import TrustPilot from "../../components/TrustPilot";
import PropertyListAPI from "../../components/PropertyListAPI.js";
import PropertyListExternal from "../../components/PropertyListExternal";
import Accordion from "../../components/Accordion";
import HotelList from "../../components/HotelList";

export default function singleRegionPage({data, pageContext}) {
  let theme = [];
  let lrHotelList = [];

	if (data.theme) {
		theme = data.theme.pageBuilder || [];
	}

  if (data.lrHotelList) {
    lrHotelList = data.lrHotelList.getProperty || [];
  }

	const content = (theme || [])
    .map((c, i) => {
      let el = null;
      switch (c._type) {
		case "searchForm":
			el = <SearchForm key={c._key} {...c} />;
		  break;
		case "hero":
			el = <Hero key={c._key} {...c} />;
			break;
		case "trustPilot":
			el = <TrustPilot key={c._key} {...c} />;
			break;
		case "copyImageBlock":
			el = <CopyImageBlock key={c._key} {...c} />;
		  break;
		case "linkBlock":
			el = <LinkBlock key={c._key} {...c} />;
		  break;
		case "linkList":
			el = <LinkList key={c._key} {...c} />;
		  break;
		case "imageGrid":
			el = <ImageGrid key={c._key} {...c} />;
      break;
		case "blogList":
			el = <BlogList key={c._key} {...c} />;
		  break;
		case "propertyListAPI":
			el = <PropertyListAPI key={c._key} {...c} tags={pageContext.tags} aid={data.settings.aid} keyword={pageContext.keyword} theme={data.theme.name} snaptripProperties={data.stprops.search.properties}/>;
			break;
    case "propertyList":
      el = <PropertyList key={c._key} {...c} theme={data.theme.name} properties={data.properties.nodes}/>;
      break;
    case "propertyListExternal":
        el=<PropertyListExternal key={c._key} {...c} />;
      break;
    case "accordion":
      el = <Accordion key={c._key} {...c} />;
      break;
    case 'hotelList':
      el = <HotelList key={c._key} {...c} lrHotelList={lrHotelList}/>;
      break;
    default:
      el = null;
    }
      return el;
    });

	return (
		<>
			<section className="page pad">
				{content}
			</section>
		</>
	);
}

export const query = graphql`
  query($slug: String!, $ids: [Int!]!, $id: String!, $nights: Int, $checkInDate: STProps_Date, $sortBy: String, $minPrice: Int, $maxPrice: Int, $region: String, $propertyType: [STProps_PropertyType!], $hotTub: Boolean, $petCount: Int, $garden: Boolean, $swimmingPool: Boolean, $beach: Boolean, $sleeps: Int, $tags: [String!], $keyword: String) {
    theme: sanityLCtheme( slug: { current: { eq: $slug } }) {
      name
      titleSEO
      descriptionSEO
      keywordsSEO
      slug {
        current
      }
      pageBuilder {
        ... on SanityHero {
          _key
          _type
          heading
          tagline
          searchForm {
            apartmentFilter
            color
            beachFilter
            cottageFilter
            description
            gardenFilter
            heading
            hottubFilter
            lodgeFilter
            luxuryFilter
            wifiFilter
            parkingFilter
            fireFilter
            babyFilter
            poolFilter
            gamesFilter
            enclosedFilter
            pubFilter
            secludedFilter
            accessibleFilter
            override
            overrideDate
            overrideNights
            overrideGuests
            petFilter
            tagline
          }
          alignText
          image {
            asset {
              fluid(maxWidth: 800, maxHeight: 440) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityTrustPilot {
          _key
          _type
        }
        ... on SanityHotelList {
          _key
          _type
          title
          subtitle
          displayAsGridBlock
          linkItem {
            copy
            link
          }
        }
        ... on SanitySearchForm {
          _key
          _type
          apartmentFilter
          color
          beachFilter
          cottageFilter
          description
          gardenFilter
          heading
          hottubFilter
          lodgeFilter
          luxuryFilter
          wifiFilter
          parkingFilter
          fireFilter
          babyFilter
          poolFilter
          gamesFilter
          enclosedFilter
          pubFilter
          secludedFilter
          accessibleFilter
          override
          overrideDate
          overrideNights
          overrideGuests
          petFilter
          tagline
        }
        ... on SanityCopyImageBlock {
          _key
          _type
          _rawCopy
          left
          link
          linkUrl
          image {
            asset {
              fixed(width: 1000) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
        ... on SanityLinkList {
          _key
          _type
          title
          linkItems {
            copy
            link
          }
        }
        ... on SanityLinkBlock {
          _key
          _type
          _rawCopy
          left
          link
          linkUrl
          image {
            asset {
              fixed(width: 1000) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
        ... on SanityImageGrid {
          _key
          _type
          title
          gridItems {
            copy
            link
            image {
              asset {
                fluid(maxWidth: 1000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityBlogList {
          _key
          _type
          subtitle
          title
          blogposts {
            image {
              asset {
                fluid(maxWidth: 1000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            slug {
              current
            }
            title
          }
        }
        ... on SanityPropertyList {
          _key
          _type
          subtitle
          title
        }
        ... on SanityPropertyListExternal {
					_key
					_type
					heading
					tagline
					id
					cards {
						name
						url
						region
						description
						image {
							asset {
								fluid(maxHeight: 800) {
									...GatsbySanityImageFluid
								}
							}
						}
					}
				}
                ... on SanityAccordion {
          _key
          _type
          accordions {
            title
            _rawCopy
          }
        }
        ... on SanityPropertyListAPI {
          _key
          _type
          id
          subtitle
          title
          nights
          minPrice
          maxPrice
          sortBy
          checkInDate
          regionOverride
          numberOfPeople
          numberOfProperties
          filters {
            title
          }
        }
      }
    }
    settings: sanitySiteSettings(_id: {eq: "LCsiteSettings"})  {
      description
      id
      logo {
        asset {
          fixed {
            src
          }
        }
      }
      favicon {
        asset {
          fixed(height: 48, width: 48) {
            src
          }
        }
      }
      image {
        asset {
          fixed(height: 400, width: 400) {
            base64
            srcWebp
            srcSetWebp
          }
        }
      }
      keywords
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      title
      defaultPropertyFilters {
        title
      }
    }
    regions: allSanityLCregionPage {
      nodes {
        slug {
          current
        }
        name
        showInNav
      }
    }
    properties: allSanityProperties(filter: {app: {regex: "/LUXC/gi"}, theme: {eq: $id}}, sort: {fields: _createdAt} ) {
      nodes {
        theme
        app
        id
        beds
        image_url
        pets
        property_name
        property_url
        region
        sleeps
        town
      }
    }
    themes: allSanityLCtheme {
      nodes {
        name
        slug {
          current
        }
        showInNav
      }
    }
    stprops: snaptripprops {
      search(params: {nights: $nights, checkin: $checkInDate, order: $sortBy, region: $region, propertyType: $propertyType, hotTub: $hotTub, petCount: $petCount, garden: $garden, swimmingPool: $swimmingPool, beach: $beach, sleeps: $sleeps, minPrice: $minPrice, maxPrice: $maxPrice, tags: $tags, keyword: $keyword}) {
        properties {
          name
          images {
            url
            caption
          }
          id
          pets
          town {
            name
          }
          link
          price {
            originalPrice
          }
          sleeps
        }
      }
    }
    lrHotelList: lateroomsprops {
      getProperty: properties(ids:$ids) {
        id
        sources
        isActive
        name
        slug
        searchFacilities
        address {
          line1
          line2
          city
          state
          postCode
          countryCode
        }
        rating {
          stars
          guestCount
          guestRating
        }
        location {
          latitude
          longitude
        }
        description
        facilities
        images {
          caption
          small
          large
        }
        rooms {
          name
          originalId
          description
          facilities
          images {
            caption
            small
            large
          }
        }
        spokenLanguages
        themes
        checkinInfo {
          beginTime
          endTime
          instructions
          specialInstructions
          minAge
        }
        checkoutInfo {
          beginTime
          endTime
          instructions
        }
        fees
        policies
        reviews {
          propertyId
          name
          date
          title
          text
          tripReason
          rating
        }
      }
    }
  }
`;